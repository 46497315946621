import { LOAN_PURPOSES } from '../../../../localizations/config/SE/sambla/loan-purposes';
import EMPLOYMENT_TYPES from '../../../../localizations/config/SE/sambla/employment-types';
import HOME_TYPES from '../../../../localizations/config/SE/sambla/home-types';
import MARITAL_STATUS from '../../../../localizations/config/SE/sambla/marital-status';
import DEPENDANTS from '../../../../localizations/config/SE/sambla/dependants';

import {
  email,
  nationalId,
  mergeLoan,
  dependants,
  purpose,
  homeType,
  civilStatus,
  homeCost,
  employmentType,
  employerName,
  monthlySalary,
} from '../../../../validators';

import { validateEmploymentSinceYear, validateEmploymentSinceMonth } from '../../../../validators/employedSince';
import phoneNumber from '../../../../validators/SE/phoneNumber';
import { validateEmploymentToYear, validateEmploymentToMonth } from '../../../../validators/employedTo';
import textResource from '../../../../utils/text-resources/sambla/SE/messages';
import { submit as applicationSubmitMapper } from '../../../../mappers/SE/application';

const tooltip = (text) => {
  const temp = {
    trigger: 'focus',
    text,
    width: 'fill',
    position: 'right',
  };
  return temp;
};

const data = {
  general: {
    mapper: applicationSubmitMapper,
  },
  links: {
    quickReapply: '/returning-customer/',
    quickReapplyEng: '/en/returning-customer/',
  },
  slides: [
    {
      title: '',
      coApplicantSlide: false,
      fields: [
        {
          component: 'TotalAmountInput',
          props: {
            name: 'totalAmount',
            placeholder: textResource.applicationLoanAmountPlaceholder,
            label: textResource.applicationLoanAmountLabel,
            suffix: textResource.applicationLoanAmountSuffix,
            whitelist: true,
            tooltip: tooltip(textResource.applicationLoanAmountTooltip),
          },
        },
        {
          component: 'MonthlyCost',
          props: {
            label: textResource.monthlyCostApproximateMonthlyCostLabel,
            costLabel: textResource.monthlyCostApproximateCostLabel,
            approximateMonthlyCostDesktop: textResource.monthlyCostApproximateCostPerMonthDesktop,
            approximateMonthlyCostMobile: textResource.monthlyCostApproximateCostPerMonthMobile,
            approximateMonthlyCostYears: textResource.monthlyCostApproximateCostLabelYears,
          },
        },
        {
          component: 'FormTextBlock',
          props: {
            text: textResource.repaymentTimeWarning,
            transparent: false,
            visibleWhen: 'return(formState.values.repaymentTime > 15)',
            center: false,
            margin: false,
          },
        },
        {
          component: 'Break',
          props: {
            shade: 'up',
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          props: {
            name: 'merge',
            label: textResource.currentLoanMergeLabel,
            hasValidateFunction: true,
            labelLayout: 'center; margin-right: 0;',
            buttonLayout: 'toggle',
            validate: mergeLoan,
            buttons: [
              {
                label: textResource.mergeLoanYes,
                value: 'true',
              },
              {
                label: textResource.mergeLoanNo,
                value: 'false',
              },
            ],
            tooltip: tooltip(textResource.currentLoanMergeTooltip),
          },
        },

        {
          component: 'ConsolidationLoanAmountInput',
          props: {
            name: 'consolidationAmount',
            consolidationLabel: textResource.combinedLoanConsolidationAmount,
            hasValidateFunction: true,
            visibleWhen: 'return (formState.values.merge === true);',
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          props: {
            name: 'purpose',
            label: textResource.purposeHeaderText,
            dynamicLabel: [
              {
                type: 'calculation',
                calculateCondition: 'return (formState.values.merge === true && ((formState.values.consolidationAmount < (formState.values.totalAmount * 0.95)) && (formState.values.totalAmount - formState.values.consolidationAmount > 5000)));',
                originalLabel: textResource.purposeHeaderText,
                values: [
                  {
                    label: textResource.newPurposeHeaderText,
                  },
                ],
              },
            ],
            hasValidateFunction: true,
            validate: purpose,
            visibleWhen: 'return (formState.values.merge === false || (formState.values.consolidationAmount < formState.values.totalAmount))',
            buttons: LOAN_PURPOSES,
            tooltip: tooltip(textResource.purposeTooltip),
          },
        },
        {
          component: 'ValidatedForm',
          form: 'EmailInput',
          sectionName: 'applicant',
          props: {
            name: 'email',
            label: textResource.emailLabel,
            validate: email,
            placeholder: textResource.emailPlaceholder,
            tooltip: tooltip(textResource.emailTooltip),
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'phoneNumber',
            label: textResource.phoneNumberLabel,
            validate: phoneNumber,
            hasValidateFunction: true,
            type: 'tel',
            placeholder: textResource.phoneNumberPlaceholder,
            tooltip: tooltip(textResource.phoneNumberTooltip),
          },
        },
        {
          component: 'AcceptNewsLetter',
          form: 'Checkbox',
          props: {
            name: 'acceptNewsletter',
            text: textResource.samblaPlusText,
            linkText: textResource.samblaPlusReadMore,
            links: {
              SV: 'https://www.sambla.se/om-sambla/sambla-plus/',
              EN: 'https://www.sambla.se/en/about-sambla/sambla-plus/',
            },
          },
        },
        {
          component: 'Teleporter',
          sectionName: 'applicant',
          props: {
            destinationIndex: 2,
            sendAbandonCartEvent: true,
            label: textResource.nextSlide,
            ctaBtnStyle: true,
            checkForReturningCustomer: false,
          },
        },
        {
          component: 'UserAgreementDataPolicy',
          props: {
            lead: true,
            userAgreement: 'https://www.sambla.se/om-sambla/anvandarvillkor/',
            userAgreementEng: 'https://www.sambla.se/en/user-agreement/',
            dataPolicy: 'https://www.sambla.se/om-sambla/dataskyddspolicy/',
            dataPolicyEng: 'https://www.sambla.se/en/data-protection-policy/',
          },
        },
        {
          component: 'FormBottom',
          props: {
            label: textResource.safeAndSecure,
            trustPilotData: {
              locale: 'sv-SE',
              buData: '55152ede0000ff00057e5303',
            },
          },
        },
      ],
    },
    {
      title: textResource.slideTitleOne,
      coApplicantSlide: false,
      showBackButton: true,
      isReturningCustomerSlide: true,
      fields: [
        {
          component: 'ReturningCustomerSlide',
          props: {

          },
        },
      ],
    },
    {
      title: textResource.slideTitleOne,
      fullview: true,
      coApplicantSlide: false,
      showBackButton: true,
      fields: [
        {
          component: 'FormTextBlock',
          props: {
            text: textResource.positiveReinforcementStableEmployment,
            margin: true,
            transparent: true,
            type: 'error',
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'applicant',
          props: {
            name: 'civilStatus',
            label: textResource.civilStatusLabel,
            hasValidateFunction: true,
            validate: civilStatus,
            buttons: MARITAL_STATUS,
            tooltip: tooltip(textResource.civilStatusTooltip),
          },
        },
        {
          component: 'Break',
          props: {
            shade: 'up',
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'applicant',
          props: {
            name: 'dependants',
            label: textResource.dependantsLabel,
            hasValidateFunction: true,
            buttonLayout: 'compact',
            validate: dependants,
            buttons: DEPENDANTS,
            tooltip: tooltip(textResource.dependantsTooltip),
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'nationalId',
            label: textResource.nationalIdLabel,
            validate: nationalId,
            placeholder: textResource.nationalIdPlaceholder,
            hasValidateFunction: true,
            type: 'tel',
            tooltip: tooltip(textResource.nationalIdTooltip),
          },
        },
        {
          component: 'Teleporter',
          sectionName: 'applicant',
          props: {
            destinationIndex: 3,
            label: textResource.nextSlide,
            ctaBtnStyle: true,
          },
        },
      ],
    },
    {
      title: textResource.slideTitleTwo,
      fullview: true,
      coApplicantSlide: false,
      showBackButton: true,
      fields: [
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'applicant',
          props: {
            name: 'employmentType',
            label: textResource.employmentTypeLabel,
            hasValidateFunction: true,
            validate: employmentType,
            buttons: EMPLOYMENT_TYPES,
            tooltip: tooltip(textResource.employmentTypeTooltip),
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            isNumber: true,
            name: 'monthlySalary',
            label: textResource.monthlySalaryLabel,
            placeholder: textResource.monthlySalaryPlaceholder,
            validate: monthlySalary,
            hasValidateFunction: true,
            type: 'tel',
            tooltip: tooltip(textResource.monthlySalaryTooltip),
          },
        },
        {
          component: 'EmploymentSinceInputYear',
          sectionName: 'applicant',
          props: {
            name: 'employmentSince',
            label: textResource.employmentSinceYearLabel,
            dynamicLabel: [
              {
                key: 'employmentType',
                originalLabel: textResource.employmentSinceYearLabel,
                values: [
                  {
                    name: 'retired',
                    sectionName: 'applicant',
                    label: textResource.retiredSinceDynamicLabel,
                  },
                  {
                    name: 'disabilityPension',
                    sectionName: 'applicant',
                    label: textResource.disabilityPensionSinceDynamicLabel,
                  },
                ],
              },
            ],
            placeholder: textResource.employmentSinceYearPlaceholder,
            validate: validateEmploymentSinceYear,
            hasValidateFunction: true,
            tooltip: tooltip(textResource.employmentSinceYearTooltip),
          },
        },
        {
          component: 'EmploymentSinceInputMonth',
          sectionName: 'applicant',
          props: {
            name: 'employmentSinceMonth',
            validate: validateEmploymentSinceMonth,
            hasValidateFunction: true,
            label: textResource.employmentSinceMonthLabel,
            placeholder: textResource.employedSinceMonthPlaceholder,
            visibleWhen: 'return (formState.values.applicant.employmentSince > new Date().getFullYear() - 4);',
            tooltip: tooltip(textResource.employmentSinceMonthTooltip),
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'employmentTo',
            label: textResource.employedToYearLabel,
            validate: validateEmploymentToYear,
            hasValidateFunction: true,
            type: 'tel',
            placeholder: textResource.employedToYearPlaceholder,
            tooltip: tooltip(textResource.employedToTooltip),
            visibleWhen: 'return (formState.values.applicant.employmentType === "project");',
          },
        },
        {
          component: 'EmploymentSinceInputMonth',
          sectionName: 'applicant',
          props: {
            name: 'employmentToMonth',
            label: textResource.employedToMonthLabel,
            validate: validateEmploymentToMonth,
            hasValidateFunction: true,
            placeholder: textResource.employedToMonthPlaceholder,
            visibleWhen: 'return (formState.values.applicant.employmentType === "project");',
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'employerName',
            validate: employerName,
            hasValidateFunction: true,
            placeholder: textResource.employerNamePlaceholder,
            tooltip: tooltip(textResource.employerNameTooltip),
            label: textResource.employerNameLabel,
            visibleWhen: 'return (formState.values.applicant.employmentType !== undefined && formState.values.applicant.employmentType !== "disabilityPension" && formState.values.applicant.employmentType !== "retired");',
          },
        },
        {
          component: 'Teleporter',
          sectionName: 'applicant',
          props: {
            destinationIndex: 4,
            label: textResource.nextSlide,
            ctaBtnStyle: true,
          },
        },
      ],
    },
    {
      title: textResource.slideTitleThree,
      fullview: true,
      coApplicantSlide: false,
      showBackButton: true,
      fields: [
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'applicant',
          props: {
            name: 'homeType',
            label: textResource.homeTypeLabel,
            hasValidateFunction: true,
            validate: homeType,
            buttons: HOME_TYPES,
            tooltip: tooltip(textResource.homeTypeTooltip),
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'homeCost',
            isNumber: true,
            label: textResource.homeCostLabel,
            validate: homeCost,
            placeholder: textResource.homeCostPlaceholder,
            hasValidateFunction: true,
            type: 'tel',
            tooltip: tooltip(textResource.homeCostTooltip),
          },
        },
        {
          component: 'Break',
          props: {
            shade: 'up',
          },
        },
        {
          component: 'FormTextBlock',
          props: {
            text: textResource.addCoApplicantLabel,
            tooltip: tooltip(textResource.employerPhoneTooltip),
            transparent: true,
            header: true,
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          props: {
            label: textResource.addCoApplicantDesc,
            name: 'hasCoApplicant',
            rightPadding: true,
            buttons: [
              {
                label: textResource.mergeLoanYes,
                value: 'true',
              },
              {
                label: textResource.mergeLoanNo,
                value: 'undefined',
              },
            ],
            tooltip: tooltip(textResource.addCoApplicantTooltip),
          },
        },
        {
          component: 'Break',
          props: {
            shade: 'down',
          },
        },
        {
          component: 'Teleporter',
          sectionName: 'applicant',
          props: {
            destinationIndex: 5,
            label: textResource.nextSlide,
            ctaBtnStyle: true,
            visibleWhen: 'return (formState.values.hasCoApplicant === true);',
          },
        },
        {
          component: 'SubmitButton',
          sectionName: 'applicant',
          props: {
            label: textResource.formSubmit,
            ctaBtnStyle: true,
            messages: {
              uncaughtSubmitError: textResource.submitErrorUncaught,
              generalSubmitError: textResource.submitErrorGeneral,
            },
            visibleWhen: 'return (formState.values.hasCoApplicant !== true);',
          },
        },
      ],
    },
    {
      title: textResource.slideTitleFour,
      fullview: true,
      coApplicantSlide: true,
      showBackButton: true,
      fields: [
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'nationalId',
            label: textResource.coApplicantNationalIdLabel,
            placeholder: textResource.coApplicantNationalIdPlaceholder,
            validate: nationalId,
            hasValidateFunction: true,
            type: 'tel',
            tooltip: tooltip(textResource.coApplicantNationalIdTooltip),
          },
        },
        {
          component: 'ValidatedForm',
          form: 'EmailInput',
          sectionName: 'coApplicant',
          props: {
            name: 'email',
            label: textResource.coApplicantEmailLabel,
            placeholder: textResource.coApplicantEmailPlaceholder,
            validate: email,
            tooltip: tooltip(textResource.coApplicantEmailTooltip),
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'phoneNumber',
            label: textResource.coApplicantPhoneNumberLabel,
            placeholder: textResource.coApplicantPhoneNumberPlaceholder,
            validate: phoneNumber,
            hasValidateFunction: true,
            type: 'tel',
            tooltip: tooltip(textResource.coApplicantPhoneNumberTooltip),
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'coApplicant',
          props: {
            name: 'civilStatus',
            label: textResource.coApplicantCivilStatusLabel,
            hasValidateFunction: true,
            validate: civilStatus,
            buttons: MARITAL_STATUS,
            tooltip: tooltip(textResource.coApplicantCivilStatusTooltip),
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'coApplicant',
          props: {
            name: 'dependants',
            label: textResource.coApplicantDependantsLabel,
            hasValidateFunction: true,
            validate: dependants,
            buttons: DEPENDANTS,
            tooltip: tooltip(textResource.coApplicantDependantsTooltip),
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'coApplicant',
          props: {
            name: 'homeType',
            label: textResource.coApplicantHomeTypeLabel,
            hasValidateFunction: true,
            validate: homeType,
            tooltip: tooltip(textResource.coApplicantHomeTypeTooltip),
            buttons: HOME_TYPES,
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'homeCost',
            isNumber: true,
            label: textResource.coApplicantHomeCostLabel,
            placeholder: textResource.coApplicantHomeCostPlaceholder,
            validate: homeCost,
            hasValidateFunction: true,
            type: 'tel',
            tooltip: tooltip(textResource.coApplicantHomeCostTooltip),
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'coApplicant',
          props: {
            name: 'employmentType',
            label: textResource.coApplicantEmploymentTypeLabel,
            hasValidateFunction: true,
            validate: employmentType,
            buttons: EMPLOYMENT_TYPES,
            tooltip: tooltip(textResource.coApplicantEmploymentTypeTooltip),
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'monthlySalary',
            isNumber: true,
            label: textResource.coApplicantMonthlySalaryLabel,
            placeholder: textResource.coApplicantMonthlySalaryPlaceholder,
            validate: monthlySalary,
            hasValidateFunction: true,
            type: 'tel',
            tooltip: tooltip(textResource.coApplicantMonthlySalaryTooltip),
          },
        },
        {
          component: 'EmploymentSinceInputYear',
          sectionName: 'coApplicant',
          props: {
            name: 'employmentSince',
            validate: validateEmploymentSinceYear,
            label: textResource.coApplicantEmploymentSinceYearLabel,
            dynamicLabel: [
              {
                key: 'employmentType',
                originalLabel: textResource.coApplicantEmploymentSinceYearLabel,
                values: [
                  {
                    name: 'retired',
                    sectionName: 'coApplicant',
                    label: textResource.retiredSinceDynamicLabel,
                  },
                  {
                    name: 'disabilityPension',
                    sectionName: 'coApplicant',
                    label: textResource.disabilityPensionSinceDynamicLabel,
                  },
                ],
              },
            ],
            placeholder: textResource.coApplicantEmploymentSinceYearPlaceholder,
            hasValidateFunction: true,
            tooltip: tooltip(textResource.coApplicantEmploymentSinceYearTooltip),
          },
        },
        {
          component: 'EmploymentSinceInputMonth',
          sectionName: 'coApplicant',
          props: {
            name: 'employmentSinceMonth',
            validate: validateEmploymentSinceMonth,
            hasValidateFunction: true,
            label: textResource.coApplicantEmploymentSinceMonthLabel,
            placeholder: textResource.coApplicantEmploymentSinceMonthPlaceholder,
            visibleWhen: 'return (formState.values.coApplicant.employmentSince > new Date().getFullYear() - 4);',
            tooltip: tooltip(textResource.coApplicantEmploymentSinceMonthTooltip),
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'employmentTo',
            label: textResource.coApplicantEmployedToYearLabel,
            validate: validateEmploymentToYear,
            hasValidateFunction: true,
            type: 'tel',
            placeholder: textResource.coApplicantEmployedToYearPlaceholder,
            tooltip: tooltip(textResource.coApplicantEmployedToTooltip),
            visibleWhen: 'return (formState.values.coApplicant.employmentType === "project");',
          },
        },
        {
          component: 'EmploymentSinceInputMonth',
          sectionName: 'coApplicant',
          props: {
            name: 'employmentToMonth',
            label: textResource.coApplicantEmployedToMonthLabel,
            validate: validateEmploymentToMonth,
            hasValidateFunction: true,
            placeholder: textResource.employedToMonthPlaceholder,
            visibleWhen: 'return (formState.values.coApplicant.employmentType === "project");',
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'employerName',
            validate: employerName,
            hasValidateFunction: true,
            label: textResource.coApplicantEmployerNameLabel,
            placeholder: textResource.coApplicantEmployerNamePlaceholder,
            tooltip: tooltip(textResource.coApplicantEmployerNameTooltip),
            visibleWhen: 'return (formState.values.coApplicant.employmentType !== undefined && formState.values.coApplicant.employmentType !== "disabilityPension" && formState.values.coApplicant.employmentType !== "retired");',
          },
        },
        {
          component: 'SubmitButton',
          sectionName: 'coApplicant',
          props: {
            label: textResource.formSubmit,
            ctaBtnStyle: true,
            messages: {
              uncaughtSubmitError: textResource.submitErrorUncaught,
              generalSubmitError: textResource.submitErrorGeneral,
            },
          },
        },
      ],
    },
  ],
};

export default data;
