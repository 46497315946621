import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import intlShape from '../../../schemas/intl';
import stripDelimiter from '../../../utils/strip-delimiter-from-currency';
import tooltipToObject from '../../../helpers/tooltipToObject';
import Tooltips from '../../../../components/utils/tooltips';
import { addThousandSeparator } from '../../../utils/formatters/add-thousand-separator';

const TlTable = styled.div`
  && {
    background: #fff;
    padding: 1rem;
    border-radius: 1rem;
    margin-bottom: 1rem;
  }
`;
const TlTableTitleRow = styled.div`
  && {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
`;
const TlTableRow = styled.div`
  && {
    display: flex;
    justify-content: space-between;
  }
`;
const TlTableBreak = styled.div`
  && {
    height: 1px;
    width: 100%;
    margin: 1rem 0;
    background: #cacaca;
  }
`;
const SumTotal = styled.div`
  && {
    font-weight: bold;
    color: #FF5E00;
  }
`;

const TestCurrentLoanSummary = ({
  data,
  // eslint-disable-next-line react/prop-types
  intl: { formatMessage },
}) => {
  const [figures, setFigures] = useState({});
  const formState = useSelector((state) => state.form?.application?.values);

  const tooltipObject = tooltipToObject(data.tooltip);
  if (typeof data.tooltip === 'object') { tooltipObject.text = formatMessage(data.tooltip.text); }

  const buildRows = () => {
    let cls = 0;
    let appCls = 0;
    let coAppCls = 0;
    let nla = 0;

    if (formState?.applicant?.currentLoans?.refinanceSum) {
      // eslint-disable-next-line
      appCls = parseInt(stripDelimiter(formState?.applicant?.currentLoans?.refinanceSum), 10);
    }
    if (formState?.coApplicant?.currentLoans?.refinanceSum) {
      // eslint-disable-next-line
      coAppCls = parseInt(stripDelimiter(formState?.coApplicant?.currentLoans?.refinanceSum), 10);
    }

    cls = appCls + coAppCls;

    if (formState?.totalAmount && cls <= formState?.totalAmount) {
      nla = parseInt(stripDelimiter(formState?.totalAmount), 10) - cls;
    }

    setFigures({
      newLoanAmount: nla,
      currentLoansSum: cls,
      totalAmount: cls + nla,
    });
  };

  useEffect(() => {
    buildRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formState?.applicant?.currentLoans?.refinanceSum,
    formState?.coApplicant?.currentLoans?.refinanceSum,
    formState?.totalAmount,
    formState?.applicant?.currentLoans,
    formState?.coApplicant?.currentLoans,
  ]);

  return (
    <TlTable>
      <TlTableTitleRow>
        <b>{formatMessage(data.title)}</b>
        <>
          {tooltipObject.text && (
            <Tooltips tooltipObject={tooltipObject} />
          )}
        </>
      </TlTableTitleRow>
      <TlTableRow>
        <div>{formatMessage(data.newLoanLabel)}</div>
        <div>
          {addThousandSeparator(figures.newLoanAmount)}
          {' '}
          {formatMessage(data.suffix)}
        </div>
      </TlTableRow>
      <TlTableRow>
        <div>{formatMessage(data.refinanceLoanLabel)}</div>
        <div>
          {addThousandSeparator(figures.currentLoansSum)}
          {' '}
          {formatMessage(data.suffix)}
        </div>
      </TlTableRow>
      <TlTableBreak />
      <TlTableRow>
        <b>{formatMessage(data.totalLabel)}</b>
        <SumTotal>
          {addThousandSeparator(figures.totalAmount)}
          {' '}
          {formatMessage(data.suffix)}
        </SumTotal>
      </TlTableRow>
    </TlTable>
  );
};

TestCurrentLoanSummary.propTypes = {
  data: PropTypes.shape().isRequired,
  intl: intlShape.isRequired,
};

const TestCurrentLoanSummaryWithIntl = injectIntl(TestCurrentLoanSummary);

export default TestCurrentLoanSummaryWithIntl;
