import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import intlShape from '../../../schemas/intl';

const TotalLoanWarningWrap = styled.div`
  && {
    background: #C00000;
    padding: 12px 20px;
    border-radius: 1rem;
    white-space: pre-line;
    color: white;
    margin-bottom: 1rem;
  }
`;

const TotalLoanWarning = ({
  intl: { formatMessage },
  data,
}) => {
  const formState = useSelector((state) => state.form.application);
  const diff = formState.values.applicant.currentLoans?.diff || 0;
  const label = `Nykyinen ero: ${diff} €`;

  return (
    <>
      { diff < 0 && (
        <TotalLoanWarningWrap>
          {formatMessage(data.text)}
          <p>
            {label}
          </p>
        </TotalLoanWarningWrap>
      )}
    </>
  );
};

TotalLoanWarning.propTypes = {
  data: PropTypes.shape(),
  intl: intlShape.isRequired,
};

TotalLoanWarning.defaultProps = {
  data: {},
};

export default injectIntl(TotalLoanWarning);
